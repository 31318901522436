import { createSlice } from '@reduxjs/toolkit';

// Оголошення початкового стану
const initialState = {
  location: '',
  services: '',
  name: '',
  phone: '',
  status: 'Физическим лицам',
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    // Оновлення стану з payload
    update: (state, action) => {
      // Непряма модифікація стану за допомогою immer (через Redux Toolkit)
      Object.assign(state, action.payload);
    },
  },
});

// Експортуємо екшн
export const { update } = servicesSlice.actions;

// Експортуємо редюсер
export default servicesSlice.reducer;
