export const uk = {
  header: {
    inputLoaction: {
      placeholder: 'Вкажіть місто',
    },
    selectInput: {
      individuals: 'Фізичним особам',
      legal: 'Юридичним особам',
    },
  },
  maine: {
    title: {
      text: 'Послуги детектива',
      location: 'в Європі та Украіні',
    },
    subTitle: {
      text: 'довір своє питання нашим детективам і отримай перші результати за',
      time: '24 ГОДИНИ',
    },
    button: {
      text: 'Записатися на консультацію',
    },
  },
  services: {
    title: {
      text: 'Послуги детектива',
    },
    selectInput: {
      individuals: 'Фізичним особам',
      legal: 'Юридичним особам',
    },
    card: {
      buttonOrder: {
        text: 'Замовити',
      },
      buttonDetails: {
        text: 'Деталі',
      },
    },
    description: {
      button: {
        text: 'назад',
      },
    },
  },
  steps: {
    title: 'Як ми надаємо послуги детектива?',
    numberOne: {
      title: 'Дзвінок',
      description: 'Ви записуєтеся до нас на консультацію',
    },
    numberTwo: {
      title: 'Консультація',
      description: 'Ви отримуєте консультацію нашого спеціаліста',
    },
    numberThree: {
      title: 'Інструкція',
      description:
        'Ви отримуєте чіткі та зрозумілі покрокові інструкції щодо того, що слід зробити для вирішення Вашої проблеми',
    },
    numberFour: {
      title: 'Інформація',
      description:
        'Ви отримуєте чітку і повну інформацію про вартість наших послуг на випадок, якщо захочете замовити їх у наших спеціалістів',
    },
    numberFive: {
      title: 'Замовлення',
      description:
        'Ви робите замовлення з необхідним переліком послуг і отримуєте їх в повному обсязі, гарантовано',
    },
  },
  gallery: {
    title: 'Наші приватні детективи працюють як онлайн, так і офлайн.',
    description: {
      title: 'Наші послуги',
      text: 'Ми надаємо професійні консультаційні послуги з питань приватних розслідувань та супутньої інформаційної підтримки. Всі наші дії та робота здійснюються виключно в межах чинного законодавства України. Ми суворо дотримуємося правових норм та стандартів етики, забезпечуючи конфіденційність та безпеку наших клієнтів. Правова відповідність Ми підкреслюємо, що наша діяльність не включає жодних незаконних дій або порушень приватного життя. Всі консультації та інші послуги надаються у відповідності до законів України про приватну детективну діяльність, а також з урахуванням усіх вимог до обробки та збереження інформації.',
    },
  },
  footer: {
    location: {
      title: 'Адреса',
      link: {
        text: 'C/ de les Barques, 3, Ciutat Vella, 46002 Valencia, Valencia, Spain',
      },
    },
    mail: {
      title: 'Пошта',
    },
    socialLinks: {
      title: 'Соціальні посилання',
    },
  },
  modal: {
    title: 'Замовити послугу',
    inputName: {
      invalidCharacters:
        "Ім'я може містити лише літери, апостроф, дефіс та пробіли",
      invalidMessage: 'Невірний формат імені мінімум 4 символи',
      errorMessage: "Введіть ім'я",
      label: "Ім'я",
    },
    inputPhone: {
      errorMessage: 'Введіть номер телефону',
      invalidMessage:
        'Будь ласка, введіть повний номер телефону (мінімум 10 цифр)',
      label: 'Номер телефону',
    },
    buttonSubmit: 'Відправити',
    buttonSubmitting: 'Відправка...',
  },
  successPage: {
    title: "Успішно!",
    message: "Ваш запит було прийнято. Ми зв'яжемось з вами найближчим часом.",
    homeButton: "На головну"
  },
};
