import { Suspense, lazy } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import LogoRus from './icon/Logo-rus';
import { Box } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';

// Lazy-loaded pages and components
const Home = lazy(() => import('./pages/Home'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const Widget = lazy(() => import('./components/Widget'));

const ServiceDetails = lazy(() =>
  import('./pages/ServiceDetails/ServiceDetails')
);

const SuccessPage = lazy(() =>
  import('./pages/SuccessPage').then((module) => ({
    default: module.SuccessPage,
  }))
);

function App() {
  const location = useLocation();

  return (
    <Suspense
      fallback={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <LogoRus className="spiner" width="100%" height="100%" />
          </motion.div>
        </Box>
      }
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Widget />

        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services/:id" element={<ServiceDetails />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </AnimatePresence>

        {location.pathname !== '/success' && <Footer />}
      </motion.div>
    </Suspense>
  );
}

export default App;
